/** @jsx jsx */
import { jsx, Heading, Text, Box } from "theme-ui"

import Layout from "../components/Layout"

const LocalGroupPage = () => {
  return (
    <Layout>
      <Box bg="#fff">
        <Box
          mx="auto"
          py={[60, 60, 100]}
          px={[20, 20, 40]}
          sx={{ maxWidth: 650 }}
        >
          <Heading sx={{ mb: 4 }}>The Local Group</Heading>

          <Text variant="subtitle">
            Congratulations on finding our hidden page!
          </Text>
          <Text variant="paragraph">
            So hidden in fact, we haven&apos;t had a chance to code it yet.
          </Text>

          <Text variant="paragraph">
            Please check back here soon to find out more about The Local Group.
          </Text>
        </Box>
      </Box>
    </Layout>
  )
}

export default LocalGroupPage
